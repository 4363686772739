import React from "react";
import '../App.css';
import { NavBar } from "../components/navbar";
import { ContentBlock } from "../components/ContentBlock";
import { Link } from "react-router-dom";
import { Footer } from "../components/footer";

const scrollToTop = () =>{
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
    });
  };

let content1 = ["It is time for put our money where our carbon is. We cannot pretend to be advocates for “clean technology” and continue to waste energy and money in a grossly inefficient home. Tinkering with insulation is not enough: we intend to reduce our energy bills to zero and be fully carbon neutral by 2025."]

let objectivesContent = [
  <ul>
    <li>Make more power than we consume</li>
    <li>Avoid excessive interior remodeling</li>
    <li>Keep out the stifling heat in summer</li>
    <li>Gain a warm and comfortable home in winter</li>
  </ul>
]

let statusContent = ["Goldfinch Energy has completed its audit of our energy consumption and has provided us with a short list of three options involving a combination of heat pump, solar and infrared technology.  We are currently evaluating the options and getting specific quotes from suppliers."]

let blogDescription = ["Welcome to the launch of Fairvalley Energy We are determined to deal with our personal carbon footprint, and we now know what it will take to become carbon neutral.", <br/>, <br/>, "Follow our progress and you can too."]

const FairValleyEnergyPage = () => {
    return(
    <div className="App">
      <br/>
      <br/>
      <br/>
      <br/>
      <NavBar/>
      <ContentBlock 
        title="Fair Valley Energy"
        id="Story"
        image={"./img14.jpg"}
        content={content1}/>
      <ContentBlock 
        title="Project Objectives"
        id="Services"
        image={"./img15.jpg"}
        content={objectivesContent}/>
      <ContentBlock 
        title="Project Status"
        id="CleanTech"
        image={"./img16.jpeg"}
        content={statusContent}/>
      <ContentBlock
        title="Blog"
        id="CleanTech"
        image={"./img17.jpeg"}
        content={blogDescription}
        hasButton={true}
        page="/fair_valley_energy_blog"/>
      {/* <div id="fvEnergyScroll"/>
      <Blog id="fvEnergyBlog"/> */}
      <Footer/>
      
    </div>
    );
}


export default FairValleyEnergyPage;
