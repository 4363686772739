import React, { Component } from "react";
import '../App.css';
import { NavBar } from "../components/navbar";
import emailjs from 'emailjs-com';

class PostForm extends Component {
    constructor(props){
        super(props)
        this.state = {
            contact_name: '',
            contact_email: '',
            contact_message: '',
            contact_service_1: '',
            contact_service_2: '',
            contact_service_3: '',
            sent: false
        }
        this.changeHandler = this.changeHandler.bind(this);
	    this.checkHandler = this.checkHandler.bind(this);
        this.submitHandler = this.submitHandler.bind(this);
    }
    changeHandler = (e) => {
        this.setState({[e.target.name]: e.target.value});
    }

    checkHandler = (e) => {
        this.setState({[e.target.name]: e.target.checked})
    }

    submitHandler (e) {
        e.preventDefault();
        var contact_services = [];
        if (this.state.contact_service_1) {
                contact_services.push("Organizational Development & Leadership");
        }
        if (this.state.contact_service_2) {
                contact_services.push("Governance & Oversight");
        }
        if (this.state.contact_service_3) {
                contact_services.push("Cleantech Investing & Fundraising");
        }
        var templateParams = {
            from_name: this.state.contact_name,
            from_email: this.state.contact_email,
            contact_message: this.state.contact_message,
            services: contact_services
        };
        emailjs.send('service_wp8r1es','template_2cwu16g', templateParams, "user_2EhvdHxeCoGndCGfeJzAC")
            .then(function(response) {
                console.log('SUCCESS!', response.status, response.text);
            }, function(error) {
                console.log('Failed...', error);
            });
    }

    render(){
        const {contact_name, 
            contact_email,  
            contact_message,
            contact_service_1,
            contact_service_2,
            contact_service_3} = this.state
        return(
            <div className="App">
            <br/>
            <br/>
            <br/>
            <br/>
            <NavBar/>
            <div className="pageTitle">
                <br></br>
                <h1>Contact</h1>
            </div>
            <div id="container">
                <form onSubmit={this.submitHandler}>
                    <input
                        type="text"
                        name="contact_name"
                        placeholder="Full Name"
                        value={contact_name}
                        onChange={this.changeHandler}
                    />
                    <br/>
                    <input
                        type="text"
                        name="contact_email"
                        placeholder="Email"
                        value={contact_email}
                        onChange={this.changeHandler}
                    />
                    <br/>
                    <p>Services</p>
                    <label className="checkbox_container">Organizational Development & Leadership
                        <input 
                        type="checkbox" 
                        name="contact_service_1"
                        value={contact_service_1}
                        onChange={this.checkHandler}
                        >
                        </input>
                        <span className="checkmark"/>
                    </label>
                    <label className="checkbox_container">Governance & Oversight
                        <input 
                        type="checkbox" 
                        name="contact_service_2"
                        value={contact_service_2}
                        onChange={this.checkHandler}
                        >
                        </input>
                        <span className="checkmark"/>
                    </label>
                    <label className="checkbox_container">Cleantech Investing & Fundraising
                        <input 
                        type="checkbox" 
                        name="contact_service_3"
                        value={contact_service_3}
                        onChange={this.checkHandler}
                        >
                        </input>
                        <span className="checkmark"/>
                    </label>
                    <input
                        type="text"
                        name="contact_message"
                        placeholder="Message (Optional)"
                        value={contact_message}
                        onChange={this.changeHandler}
                    >
                    </input>
                    <br/>

                    <div className="centered">
                        <button type="submit" className="button learn_more_button" href="" onClick={() => this.setState({sent: true})}>Submit</button>
                    </div>
                </form>
                {this.state.sent ? <Success/> : null}
            </div>
        </div>
        
        );
    }
}
const Success = () => (
  <p className="successMessage">
    Your message has been sent!
  </p>
)

export default PostForm;

