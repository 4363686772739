import React from "react";
import './App.css';
import MainPage from "./pages/index";
import NotFoundPage from "./pages/404";
import PostForm from "./pages/ContactPage";
import ServicesPage from "./pages/ServicesPage";
import CleanTechPage from "./pages/CleanTechPage";
import FairValleyEnergyPage from "./pages/FairValleyEnergyPage";
import FairValleyGardensPage from "./pages/FairValleyGardensPage";
import BlogPost from "./pages/post";
import { HashRouter  as Router, Route, Switch, Redirect } from "react-router-dom";
import FairValleyEnergyBlog from "./pages/FairValleyEnergyBlog";
import FairValleyGardensBlog from "./pages/FairValleyGardensBlog";


function App(){
  return(
    <Router basename="/#">
      <Switch>
        <Route exact path="/" component={MainPage}/>
        <Route exact path="/404" component={NotFoundPage}/>
        <Route exact path="/contact" component={PostForm}/>
        <Route exact path="/services" component={ServicesPage}/>
        <Route exact path="/cleantech" component={CleanTechPage}/>
        <Route exact path="/fair_valley_energy" component={FairValleyEnergyPage}/>
        <Route exact path="/fair_valley_gardens" component={FairValleyGardensPage}/>
        <Route exact path="/fair_valley_energy_blog" component={FairValleyEnergyBlog}/>
        <Route exact path="/fair_valley_gardens_blog" component={FairValleyGardensBlog}/>
        <Route exact path="/post" component={BlogPost}/>
        <Route path='/post/:blogPost' component={BlogPost}/>
        <Redirect to="/404"/>
        <Redirect to="/contact"/>
        <Redirect to="/services" />
        <Redirect to="/cleantech" />
        <Redirect to="/fair_valley_energy" />
        <Redirect to="/fair_valley_gardens" />
        <Redirect to="/post" />
      </Switch>
    </Router>

  );
}

export default App;


