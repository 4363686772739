import React from "react";
import '../App.css';
import { NavBar } from "../components/navbar";
import styled from "styled-components";
import moment from 'moment';
import * as Markdown from 'react-markdown';
import { Link } from "react-router-dom";

const BlogWrapper = styled.div`
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    padding: 30px;
    border-radius: 5px;
    background-color: white;
    width: 50%;
    margin: auto;
    margin-top: 25px;
    margin-bottom: 5px;
    @media (max-width: 1000px) {
        width: 95%;
    }
`;
const Separator = styled.div`
    margin-bottom: 30px;
    width:35px;
    height:7px;
    background: #f19122;
    border:none;
    margin-top: 30px;
`;

const Date = styled.div`
    position: relative;
    font-family: "Montserrat", Arial, sans-serif;
`;


const BlogPost = ({ location: { state: { props } }}) => {
    return(
    <div>
      <NavBar/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <BlogWrapper>
            <h1>{props.title}</h1>
            <Date>
                {/* <img src="https://cdn.onlinewebfonts.com/svg/img_222368.png" 
                style={{
                    width: 20, 
                    marginRight: 5
                    }}></img> */}
                    {moment(props.date).calendar(null, {
                        sameDay: '[Today]',
                        lastDay: '[Yesterday]',
                        lastWeek: '[Last] dddd',
                        sameElse: 'MMM Do YYYY'
                    })}
            </Date>
            <Separator/>
            {/* <p>{props.content}</p> */}
            <Markdown children={props.content}/>
            
        </BlogWrapper>
    </div>
    )};

export default BlogPost
