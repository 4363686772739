import React from "react";
import styled from "styled-components";
import '../../App.css';
import { HashLink } from 'react-router-hash-link';

const NavButtonsContainer = styled.div`
    height: 100%;
    display:flex;
    align-items: center;
`;

const LinksWrapper = styled.ul`
    margin: 0;
    padding: 0;
    display: flex;
    height: 100%;
    list-style: none;
    text-decoration: none;
`;

const LinkItem = styled.li`
    height: 100%;
    padding: 0 1.1em;
    color: #144b52;
    font-weight: 500;
    font-size: 17px;
    align-items: center;
    justify-contents: center;
    display:flex;
    border-top: 4px solid transparent;
    transition: all 150ms ease-in-out;
    &:hover {
        border-top: 4px solid #f19122;
    }
    cursor: pointer;
`;


const StyledHashLink = styled.a`
    height: 100%;
    margin-top: 10px;
    color: #144b52;
    font-weight: 500;
    font-size: 17px;
    align-items: center;
    justify-contents: center;
    display:flex;
    padding-bottom: 15px;
`;

const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -80;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' })
}

export function NavButtons(props){
    return <NavButtonsContainer>
        <LinksWrapper>
            <LinkItem>
                <HashLink smooth to='/#Story' scroll={el => scrollWithOffset(el)} style={{ textDecoration: 'none', 'color': '#144b52'}}>
                    Our Story
                </HashLink>
            </LinkItem>
            <LinkItem>
                <HashLink smooth to='/#Services' scroll={el => scrollWithOffset(el)} style={{ textDecoration: 'none', 'color': '#144b52'}}>
                    Our Services
                </HashLink>
            </LinkItem>
            <LinkItem>
                <HashLink smooth to='/#CleanTech' scroll={el => scrollWithOffset(el)} style={{ textDecoration: 'none', 'color': '#144b52'}}>
                    CleanTech
                </HashLink>
            </LinkItem>
            <LinkItem>
                <HashLink smooth to='/#fv_energy' scroll={el => scrollWithOffset(el)} style={{ textDecoration: 'none', 'color': '#144b52'}}>
                    Fair Valley Energy
                </HashLink>
            </LinkItem>
            <LinkItem>
                <HashLink smooth to='/#fv_gardens' scroll={el => scrollWithOffset(el)} style={{ textDecoration: 'none', 'color': '#144b52'}}>
                    Fair Valley Gardens
                </HashLink>
            </LinkItem>
            <div className="dropdown">
                <LinkItem>Latest Posts</LinkItem>
                <div className="dropdown-content">
                    <HashLink style={{ textDecoration: 'none'}} smooth to="/fair_valley_energy_blog" >
                        <StyledHashLink>Fair Valley Energy</StyledHashLink>
                    </HashLink>
                    <HashLink style={{ textDecoration: 'none' }} smooth to="/fair_valley_gardens_blog">
                        <StyledHashLink>Fair Valley Gardens</StyledHashLink>
                    </HashLink>
                </div>
            </div>
        </LinksWrapper>
    </NavButtonsContainer>
}