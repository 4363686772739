import React from "react";
import styled from "styled-components";


const LogoWrapper = styled.div`
    display:flex;
    align-items: center;
`;

const LogoImage = styled.div`
    width: 30px;
    img{
        width: 100%
        height: 100% auto;
    }
`;
const scrollToTop = () =>{
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
    });
  };

export function Logo(){
    return <LogoWrapper >
        <LogoImage >
            <img src="/logo.jpg" className="header-logo" alt="strategyco logo" onClick={scrollToTop()}/>
        </LogoImage>
    </LogoWrapper>

}