import React from "react";
import styled from "styled-components";

const FooterContainer = styled.div`
    width: 100%;
    height: 175px;
    box-shadow: 0px 0 10px rgba(15,15,15,0.15);
    bottom:0;
    z-index:100;
    background-color:white;
    margin-top: 2vw;
    padding-top: 20px;
`;


const IconsContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 20px;
    grid-template-areas:
    ". . phone email . .";
`;

const Phone = styled.div`
    grid-area: phone;
    text-align: center;
`;
const Email = styled.div`
    grid-area: email;
    text-align: center;
`;

const SelfPromotion = styled.p`
    text-align: center;
    font-size: 12px;
`;


export class Footer extends React.Component {
    render(){
        return(
            <div>
                {/* <Container> */}
                    <FooterContainer>
                        <IconsContainer>
                            <Phone>
                                <img src='/phone.png' alt="phone icon" width="40px"/>
                                <p>416.804.6694</p>
                            </Phone>
                            <Email>
                                <img src='/email.png' alt="email icon" width="40px"/>
                                <p>lyle@strategyco.ca</p>
                            </Email>
                        </IconsContainer>
                        <SelfPromotion>Website Design: &nbsp;
                            <a href="https://duncansclarke.github.io" style={{'textDecoration': 'none', color: 'black'}}>
                                duncansclarke.github.io
                            </a>
                        </SelfPromotion>
                    </FooterContainer>
                {/* </Container> */}
            </div>
        )
    }
}