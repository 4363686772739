import React from "react";
import '../App.css';
import { NavBar } from "../components/navbar";
import { ContentBlock } from "../components/ContentBlock";
import { Footer } from "../components/footer";

// setup content

let storyContent = ["Our ability to earn a living from consuming carbon is coming to an end - fast. We can either use it as an opportunity to create value and make money, or we can let the climate crisis destroy our standard of living. There is no other choice."
                , <br/>, <br/>,
              "StrategyCo is active in the environmental and innovation sectors of the Greater Toronto Area (GTA),  one of the largest centres of clean technology in the world. This has provided a unique perspective on the opportunities and challenges that must be met for both economic and environmental success."
                , <br/>, <br/>,
              "We can turn healthy profits into a healthy planet."]

let servicesContent = ["Lyle Clarke is available to provide management, leadership, governance and advisory services to a wide variety of companies. Whether your organization is engaged in the environmental, cleantech, innovation or commercialization sector, we can work with you on a fee-for-service, contingency or “sweat equity” basis.",
  <br/>, <br/>,
  "We can help you get to the next level."
]

let cleanTechContent = ["Every innovation must overcome the  “valley of death”: the period of negative cash flow before commercial success.  Nothing has a deeper and wider valley than innovations that promise a healthier planet. Change that can transform entire industries takes time, capital, and perseverance, but promises rich rewards.",
  <br/>, <br/>,
  "Cleantech can compete successfully for capital and achieve exceptional returns in a world committed to carbon neutrality."
]

let fv_energyContent = ["Weaning off carbon means fundamentally transforming how we move and how we make things. Individual action alone cannot do it all, but it can certainly help. Advances in solar and HVAC technology have made it feasible for homeowners to achieve carbon neutrality, for about the cost of a new car. Follow a project to turn a single family home from an energy hog to a net supplier to the grid.",
  <br/>, <br/>,
  "You too can have a carbon neutral home."
]

let fv_gardensContent = ["Biodiversity is crucial to the health of our planet and the human species. Fortunately, the actions we take to preserve and protect wildlife and wild spaces will also help us address climate change.",  
  <br/>, <br/>, 
  "Follow the transformation of the grounds and woodlot of a home in Whitby, Ontario Canada from a monoculture rife with invasive species to a vibrant and beautiful set of gardens based primarily on native plants.",
  <br/>, <br/>,
  "You too can create a yard that welcomes wildlife."
]


const MainPage = () => {
    return(
    <div className="App">
      <br/>
      <br/>
      <br/>
      <br/>
      <NavBar/>
      <ContentBlock 
        title="Our Story"
        id="Story"
        image={"./img1.jpg"}
        hasButton={false}
        page={null}
        content={storyContent}/>
      <ContentBlock 
        title="Our Services"
        id="Services"
        image={"./img2.jpg"}
        hasButton={true}
        page="/services"
        content={servicesContent}/>
      <ContentBlock 
        title="Accelerating Clean Technology"
        id="CleanTech"
        image={"./img3.jpg"}
        hasButton={true}
        page="/cleantech"
        content={cleanTechContent}/>
      <ContentBlock 
        title="Fair Valley Energy"
        id="fv_energy"
        image={"./img4.jpg"}
        hasButton={true}
        page="/fair_valley_energy"
        content={fv_energyContent}/>
      <ContentBlock 
        title="Fair Valley Gardens"
        id="fv_gardens"
        image={"./img5.jpg"}
        hasButton={true}
        page="/fair_valley_gardens"
        content={fv_gardensContent}/>
      <Footer/>
    </div>
    );
}


export default MainPage;

