import React from "react";
import { Link } from "react-router-dom";
import '../App.css';

// const isMobile = useMediaQuery({ maxWidth: DeviceSize.mobile })
const scrollToTop = () =>{
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
    });
  };

export class ContentBlock extends React.Component{
    render(){
        return(
        <div class="parent" id={this.props.id}>
          <div class="child">
            <hr className="anchor"/>
            <br></br>
            <h1>{this.props.title}</h1>
            <p>{this.props.content}</p>
            <div className="buttonWrapper">
              {this.props.hasButton ? 
              <Link to={this.props.page} onClick={scrollToTop} >
                <button className="button learn_more_button" href="">Learn More</button>
              </Link>  
              : null}
            </div>
          </div>
          <div class="child-image">
            <span className="helper"></span>
            <img 
              className="content-image" 
              src={this.props.image}
              width="70%"
              alt="content">
            </img>
          </div>
      </div>
    )
    }
}