import React, { useState } from "react";
import styled from "styled-components";
import { MenuToggle } from "./menuToggle";
import { HashLink } from 'react-router-hash-link';

const NavLinksContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

const LinksWrapper = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  height: 100%;
  list-style: none;
  background-color: #fff;
  width: 100%;
  flex-direction: column;
  position: fixed;
  top: 100px;
  left: 0;
`;

const LinkItem = styled.li`
  width: 100%;
  padding: 0 1.1em;
  color: #222;
  font-weight: 500;
  font-size: 20px;
  display: flex;
  margin-bottom: 10px;
`;


const Marginer = styled.div`
  height: 2em;
`;
const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -80;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' })
}

export function MobileNavLinks(props) {
  const [isOpen, setOpen] = useState(false);

  return (
    <NavLinksContainer>
      <MenuToggle isOpen={isOpen} toggle={() => setOpen(!isOpen)} />
      {isOpen && (
        <LinksWrapper>
          <LinkItem>
                <HashLink smooth to='/#Story' onClick={() => setOpen(!isOpen)} scroll={el => scrollWithOffset(el)} style={{ textDecoration: 'none', 'color': '#144b52'}}>
                    Our Story
                </HashLink>
          </LinkItem>
          <LinkItem>
                <HashLink smooth to='/#Services' onClick={() => setOpen(!isOpen)} scroll={el => scrollWithOffset(el)} style={{ textDecoration: 'none', 'color': '#144b52'}}>
                    Our Services
                </HashLink>
          </LinkItem>
          <LinkItem>
                <HashLink smooth to='/#CleanTech' onClick={() => setOpen(!isOpen)} scroll={el => scrollWithOffset(el)} style={{ textDecoration: 'none', 'color': '#144b52'}}>
                    Accelerating Clean Technology
                </HashLink>
          </LinkItem>
          <LinkItem>
                <HashLink smooth to='/#fv_energy' onClick={() => setOpen(!isOpen)} scroll={el => scrollWithOffset(el)} style={{ textDecoration: 'none', 'color': '#144b52'}}>
                    Fair Valley Energy
                </HashLink>
          </LinkItem>
          <LinkItem>
                <HashLink smooth to='/#fv_gardens' onClick={() => setOpen(!isOpen)} scroll={el => scrollWithOffset(el)} style={{ textDecoration: 'none', 'color': '#144b52'}}>
                    Fair Valley Gardens
                </HashLink>
          </LinkItem>
          <Marginer />
        </LinksWrapper>
      )}
    </NavLinksContainer>
  );
}