import React from "react";
import '../App.css';
import { NavBar } from "../components/navbar";
import { Blog } from "../components/blog/fv_energy_blog";


const FairValleyEnergyBlog = () => {
    return(
    <div className="App">
      <br/>
      <br/>
      <br/>
      <br/>
      <NavBar/>
      <div className="pageTitle">
                <br></br>
                <h1>Fair Valley Energy Blog</h1>
      </div>
      <Blog id="fvEnergyBlog"/>
      
    </div>
    );
}


export default FairValleyEnergyBlog;
