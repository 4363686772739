import React from "react";
import '../App.css';
import { NavBar } from "../components/navbar";
import { Link } from "react-router-dom";
import { ContentBlock } from "../components/ContentBlock";
import { Footer } from "../components/footer";

let content1 = ["StrategyCo specializes in providing thoughtful and inspiring leadership. Whether you are a start-up or an established firm executing a pivot, you can rely on us to help you steer through the  most turbulent waters.",
  <br/>, <br/>,
  "Ask Lyle about the many successful start-ups, pivots and transformations he has engineered over the years:",
  <br/>, <br/>,
  <Link to="/contact">
                    <button className="button learn_more_button" href="">Contact</button>
  </Link>
]

let content2 = ["StrategyCo founder and principal Lyle Clarke's board experience runs the gamut of publically-traded, private and non-for-profit companies as Chair, Committee Chair, Director and Advisor.  Current and past Board experience includes:",
<ul>
  <li>Toronto Cleantech Capital</li>
  <li>eTracks Tire Management Systems</li>
  <li>Ontario Nature </li>
  <li>Char Technologies Inc.</li>
  <li>Cleantech Capital</li>
  <li>Blue Zone Technologies</li>
  <li>Fielding Environmental</li>
  <li>Green Mantra Technologies</li>
  <li>Stewardship Ontario</li>
  <li>Waste Diversion Ontario</li>
</ul>, <br/>,
"Ask Lyle how he can help your Board become the reason for your success:",
<br/>, <br/>,
  <Link to="/contact">
                    <button className="button learn_more_button" href="">Contact</button>
  </Link>]


const ServicesPage = () => {
    return(
    <div className="App">
      <br/>
      <br/>
      <br/>
      <br/>
      <NavBar/>
      <ContentBlock 
        title="Management & Leadership"
        id="Story"
        image={"./img6.jpeg"}
        content={content1}/>
      <ContentBlock 
        title="Directing & Governing"
        id="Services"
        image={"./img7.jpeg"}
        content={content2}/>
      
      <Footer/>
    </div>
    );
}


export default ServicesPage;


