import React from "react";
import '../App.css';
import { NavBar } from "../components/navbar";
import { ContentBlock } from "../components/ContentBlock";
import { Blog } from "../components/blog/fv_gardens_blog";
import { Footer } from "../components/footer";

let content1 = ["It can sometimes seem like Canada’s wild spaces are limitless. Our landmass is enormous and our population, in comparison, is tiny. Our population is concentrated in urban areas that sprawl over some of the richest natural spaces in the world, and we have protected a miniscule amount of our collective natural heritage.", 
<br/>, <br/>, 
"But our biggest problem is our “other” view of wildlife and wild spaces. Our carefully manicured lawns and cultivated ornamental gardens drive away wildlife. They also introduce invasive plants into neighbouring woodlots, which crowd out our stunningly beautiful native plants and flowers.",
<br/>, <br/>,
"The good news is that the enormous amount of space use for lawns and curated gardens represents an enormous opportunity to reacquaint ourselves with nature. By reducing how much space we set aside for lawns and planting native varieties more suited to where we live, we can spend less time maintaining and irrigating our yards and more time enjoying them. You don’t need to abandon all your favourite flowers: you just need to intermingle them with natives, which will bring back the birds, butterflies, dragonflies and other wildlife.",
<br/>, <br/>,
"One positive outcome of Covid is that we have all spent more time in our local parks and trails, and most of us have come to recognize how vital spending time in greenspace is to our mental health. Fortunately, we don’t have to go to a campground for the experience, we can bring the experience to our homes."];

let content2 = [<ul>
  <li>Eliminate invasive non-native plants</li>
  <li>Provide habitat for wildlife</li>
  <li>Reduce demand for irrigation and labour</li>
  <li>Increase native plants, particularly plants vital to the life cycle of pollinating bees and butterflies</li>
  <li>Enjoy a beautiful garden</li>
</ul>]

let content3 = ["Most of the invasive plants are under control and the stage has been set for new gardens and planting. However, keeping the invasive plants under control is ongoing."]

let blogDescription = ["Welcome to the launch of Fairvalley Gardens", <br/>, <br/>,
"We are transforming our suburban property and woodlot from a tangle of debris and invasive plants, shrubs, and trees into an authentic recreation of the deciduous Carolinian and Great Lakes – St. Lawrence forest that was here before us."]

const fv_gardensPage = () => {
    return(
    <div className="App">
      <br/>
      <br/>
      <br/>
      <br/>
      <NavBar/>
      <ContentBlock 
        title="Fair Valley Gardens"
        id="Story"
        image={"./img5.jpg"}
        content={content1}/>
      <ContentBlock 
        title="Project Objectives"
        id="Story"
        image={"./img15.jpg"}
        content={content2}/>
      <ContentBlock 
        title="Project Status"
        id="Story"
        image={"./img18.jpeg"}
        content={content3}/>
        <br></br>
      <ContentBlock
        title="Blog"
        id="CleanTech"
        image={"./img19.jpeg"}
        content={blogDescription}
        hasButton={true}
        page="/fair_valley_gardens_blog"/>
      <Footer/>
    </div>
    );
}


export default fv_gardensPage;

