import React from "react";
import * as contentful from 'contentful';
import { BlogItem } from './blogItem';

export class Blog extends React.Component {
  state = {
    posts: []
  }
  client = contentful.createClient({
    space: 'pfn1lp2lrsw8',
    accessToken: 'MvOb_BNov5Wqn35p7xnMzkRwWkIamL0QdlTA9uhqOLQ',
  })
  componentDidMount() {
    this.fetchPosts().then(this.setPosts);
  }
  fetchPosts = () => this.client.getEntries({'content_type': 'fairValleyGardens'})
  setPosts = response => {
    this.setState({
      posts: response.items
    })
  }
  render() {
    return (
      <div>
        { this.state.posts.map(({fields}, i) => 
        <BlogItem key={i} {...fields} />
        )}
      </div>
    )
  }
}