import React from "react";
import styled from "styled-components";
import { Logo } from "../logo";
import { NavButtons } from "./navButtons";
import { useMediaQuery } from "react-responsive";
import { MobileNavLinks } from "./mobileNavButtons";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";

const DeviceSize = {
  mobile: 1200,
  tablet: 992,
  laptop: 1324,
  desktop: 2024,
};

const NavBarContainer = styled.div`
    width: 100%;
    height: 100px;
    box-shadow: 0 1px 3px rgba(15,15,15,0.15);
    display: flex;
    align-items: center;
    padding: 0 1.5em;
    top:0;
    position: fixed;
    z-index:100;
    background-color:white;
`;

const LeftSec = styled.div`
    display: flex;
    z-index: 1;
    cursor: pointer;
`;

const MidSec = styled.div`
    display: flex;
    flex 2; 
    height: 100%;
    justify-content: center;
`;

const RightSec = styled.div`
    display: flex;
    padding-right: 25px;
`;
// const scrollToTop = () =>{
//     console.log("yo")
//     window.scrollTo({
//       top: 0, 
//       behavior: 'smooth'
//     });
//   };

export function NavBar() {
    const isMobile = useMediaQuery({ maxWidth: DeviceSize.mobile })
    const history = useHistory();
    const routeChange = () =>{ 
    let path = '/'; 
    history.push(path);
  }
    return(
        <NavBarContainer >
            <LeftSec onClick={routeChange}>
                <Logo />
            </LeftSec>
            <MidSec>
                {!isMobile && <NavButtons/>}
            </MidSec>
            <RightSec>
                <Link to="/contact">
                    <button className="button learn_more_button" href="">Contact</button>
                </Link>
                {isMobile && <MobileNavLinks/>}
            </RightSec>
        </NavBarContainer>
    )
}