import React from 'react';
import moment from 'moment';
import styled from "styled-components";
import '../../App.css';
import { Link } from "react-router-dom";
import * as Markdown from 'react-markdown'

// single jsx file 'blogPage'
// each blogitem has a "read more" button that takes you to that page
// pass blog content as props


const BlogWrapper = styled.div`
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    padding: 30px;
    border-radius: 5px;
    background-color: white;
    width: 50%;
    margin: auto;
    margin-top: 25px;
    margin-bottom: 5px;
    @media (max-width: 1000px) {
        width: 80%;
    }
`;
const Separator = styled.div`
    width:35px;
    height:7px;
    background: #f19122;
    border:none;
    margin-top: 30px;
`;

const Date = styled.div`
    position: relative;
    font-family: "Montserrat", Arial, sans-serif;
`;

export const BlogItem = (props) => (
    <div>
        <BlogWrapper>
            <h2>{props.title}</h2>
            <Date>
                    {moment(props.date).calendar(null, {
                        sameDay: '[Today]',
                        lastDay: '[Yesterday]',
                        lastWeek: '[Last] dddd',
                        sameElse: 'MMM Do YYYY'
                    })}
            </Date>
            <Separator/>
            <p style={{height:"4.5vh", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}}>
                    <Markdown children={props.tagline}/>
            </p>
            <p style={{textAlign: "center", fontSize: "30px", margin:"0px"}}>...</p>
            {/* <p style={{height:"5vh", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}}>{props.content}</p> */}
            <Link to={{
                pathname: `/post/${props.path}`,
                state: { props }
            }}>
                <div style={{textAlign: 'center'}}>
                    <button className="button learn_more_button" href="">Read Full Post</button>
                </div>

                
            </Link>
        </BlogWrapper>
    </div>
  
);
