import React from "react";
import '../App.css';
import { NavBar } from "../components/navbar";
import { ContentBlock } from "../components/ContentBlock";
import { Footer } from "../components/footer";

let content1 = ["While we can’t make a living off carbon for much longer, it is certainly what we do today. Every dollar, euro or pound of wealth the world creates depends in some manner on fossil fuel consumption, even renewable energy (how do you think those wind turbines are made and transported to site?).",
    <br/>, <br/>,
    "While the mountain is daunting, it can be conquered. It starts with accepting the fact that fossil fuels are integral to our life and our economy, and will continue to have a vital, but diminishing role into the future. We need to be carbon neutral, not carbon free. It also means accepting that tinkering is not enough: entire industries will need to be transformed. Finally, and this is the good part, it means recognizing that every time humanity has emerged from this kind of transformation, it has done so healthier, wealthier and wiser.",
    <br/>, <br/>,
    "StrategyCo can help you to turn your visionary idea into transformative, disruptive and profitable change."
  ];

let content2 = ["Most inventors want to talk about their discovery, convinced that selling the magic will sell the technology. Investors and customers have little interest in how your technology works. They only want to know that it works, what value it provides, and how much money it will make. But how can investors and customers be convinced that there is value, without showing them where the value comes from?"
, <br/>, <br/>,  "They can be convinced, without the technobabble, and we can help you do it."
];

let content3 = ["Decarbonizing means changing our infrastructure, in which many billions of dollars have been invested. New approaches have risk - serious risk. Many are willing to be second, or better third. Few want to be first. How can you scale your technology if no one is ready to try something new?",
<br/>, <br/>, "It's hard, but it can be done, and we can help you do it."
];

let content4 = ["Why put money in a venture that will take years to succeed when you can get rich on the coattails of a wiz kid with a laptop? Software start-ups do attract a great deal of capital, but investment unicorns are almost as rare as the “real” ones. What is the joy in losing your money quickly? Mainstreaming the battle against the climate crisis is already attracting mainstream capital.",
    <br/>,<br/>, "Cleantech investors are ready, are you?"
];

let content5 = ["The fact is we collectively subsidize fossil fuels by giving them a pass on the damage they cause. When gasoline costs less than water, few alternatives will offer better value at a lower price. That rarity is about to change. Carbon pricing is coming, and as it does more solutions will become investible.",
    <br/>, <br/>, "We can help you discover the Cleantech Unicorns."]

const CleanTechPage = () => {
    return(
    <div className="App">
      <br/>
      <br/>
      <br/>
      <br/>
      <NavBar/>
      <ContentBlock 
        title="Accelerating Clean Technology"
        id="Story"
        image={"./img8.jpeg"}
        content={content1}/>
      <ContentBlock 
        title="The Technobabble Barrier"
        id="Services"
        image={"./img9.jpeg"}
        content={content2}/>
      <ContentBlock 
        title="The Second Mover Bias"
        id="CleanTech"
        image={"./img10.jpeg"}
        content={content3}/>
      <ContentBlock 
        title="The App Economy"
        id="CleanTech"
        image={"./img12.jpeg"}
        content={content4}/>
    <ContentBlock 
        title="Carbon Pricing"
        id="Services"
        image={"./img11.jpeg"}
        content={content5}/>  
      <Footer/>
    </div>
    );
}


export default CleanTechPage;
