import React from "react";
import '../App.css';
import { NavBar } from "../components/navbar";
import { Blog } from "../components/blog/fv_gardens_blog";
import styled from "styled-components";

const Wrapper = styled.div`
    min-height: 100vh;
    padding-bottom: 175px;
`;

const FairValleyGardensBlog = () => {
    return(
    <div className="App">
      <br/>
      <br/>
      <br/>
      <br/>
      <NavBar/>
      <Wrapper>
        <div className="pageTitle">
            <br></br>
            <h1>Fair Valley Gardens Blog</h1>
        </div>
        <Blog id="fvGardensBlog"/>
      </Wrapper>
    </div>
    );
}


export default FairValleyGardensBlog;
